import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Layout, Listing, Wrapper, SliceZone, Title, SEO, Header, PostContent } from '../components'
import website from '../../config/website'

const Hero = styled.header`
  background-color: ${props => props.theme.colors.greyLight};
  padding-top: 1rem;
  padding-bottom: 4rem;
`

const Headline = styled.p`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.grey};
  font-size: 1.25rem;
  a {
    font-style: normal;
    font-weight: normal;
  }
`

const Post = ({ data: { prismicPost, posts }, location }) => {
  const { data } = prismicPost
  return (
    <Layout customSEO>
      <SEO
        title={`${data.title.text} | ${website.titleAlt}`}
        pathname={location.pathname}
        node={prismicPost}
        article
      />
      <div class="post">
        <PostContent
          image_set={data.image_set}
          date={data.date}
          title={data.title.text}
        />
      </div>
    </Layout>
  )
}

export default Post

Post.propTypes = {
  data: PropTypes.shape({
    prismicPost: PropTypes.object.isRequired,
    posts: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
  location: PropTypes.object.isRequired,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      uid
      first_publication_date
      last_publication_date
      data {
        title {
          text
        }
        date(formatString: "MMM D")
          image_set {
            layout
            image_1 {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            image_2 {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            } 
          }
      }
    }
    posts: allPrismicPost(limit: 2, sort: { fields: [data___date], order: DESC }, filter: { uid: { ne: $uid } }) {
      nodes {
        uid
        data {
          title {
            text
          }
          date(formatString: "MMM D")
          image_set {
            layout
            image_1 {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            image_2 {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            } 
          }
        }
      }
    }
  }
`
