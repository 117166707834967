module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Yo', // Navigation and Site Title
  titleAlt: 'Brian', // Title for JSONLD
  description: 'Brian',
  headline: 'Brian',
  url: 'https://yo.bnhn.cc', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  logo: '/logos/logo-1024.jpg', // Used for SEO
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'static/images/favicon.png', // Used for manifest favicon generation
  shortName: 'Brian', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Brian', // Author for schemaORGJSONLD
  // themeColor: '#3D63AE',
  // backgroundColor: '#EBEDF2',

  twitter: '@bnhncc', // Twitter Username
  facebook: 'bnhn.cc', // Facebook Site Name
  googleAnalyticsID: 'UA-156951519-1',

  // skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
